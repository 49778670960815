<template>
  <PageSection
    class="EmailSettings"
    :page-title="$t('pages.settings.personal.email_settings.email_settings')"
    :page-tooltip="$t('pages.settings.personal.email_settings.sidebar_description')"
  >
    <div class="EmailSettingsForm">
      <form @submit.prevent="submit">
        <div class="columns">
          <div class="column is-6">
            <FormField :validator="$v.form.from_name" :label="$t('forms.from') ">
              <input
                v-model="form.from_name"
                :placeholder="currentUser.full_name"
                class="input"
                type="text"
                name="from"
                @input="$v.form.from_name.$touch()"
              >
            </FormField>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <FormField :validator="$v.form.reply_to_name" :label="$t('forms.reply_to.name') ">
              <input
                v-model="form.reply_to_name"
                placeholder=""
                class="input"
                type="text"
                name="from"
                @input="$v.form.reply_to_name.$touch()"
              >
            </FormField>
          </div>
        </div>

        <div class="columns">
          <div class="column is-6">
            <FormField :validator="$v.form.reply_to_email" :label="$t('forms.reply_to.email') ">
              <input
                v-model="form.reply_to_email"
                placeholder=""
                class="input"
                type="text"
                name="from"
                @input="$v.form.reply_to_email.$touch()"
              >
            </FormField>
          </div>
        </div>

        <div class="columns">
          <div class="column is-12">
            <FormField :validator="$v.form.signature" :label="$t('pages.settings.personal.email_settings.signature')">
              <FroalaWrapper
                v-model="form.signature"
                :config="signatureConfig"
                class="content border-default"
                name="signature"
                rows="10"
                tabindex="0"
                @input="$v.form.signature.$touch()"
              />
            </FormField>
          </div>
        </div>

        <div class="has-text-right">
          <VButton
            :loading="form.isLoading"
            class="is-primary"
          >
            {{ $t('forms.save') }}
          </VButton>
        </div>
      </form>
    </div>
  </PageSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { HF_CUSTOM_STYLE_FONT_FAMILIES } from '@hypefactors/shared/js/constants/fonts.js'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'
import FormField from '@hypefactors/shared/js/components/forms/FormField.vue'

import PageSection from '@/components/core/PageSection.vue'
import FroalaWrapper from '@/components/forms/FroalaWrapper.vue'
import Form from '@/services/forms/Form.js'

function createForm () {
  return new Form({
    from_name: { value: '', rules: { required } },
    reply_to_name: { value: '' },
    reply_to_email: { value: '' },
    signature: { value: '' }
  })
}

export default {
  components: {
    VButton,
    FormField,
    PageSection,
    FroalaWrapper
  },

  data () {
    return {
      form: createForm(),
      signatureConfig: {
        useClasses: false,
        placeholderText: this.$t('pages.settings.personal.email_settings.signature_placeholder'),
        pluginsEnabled: [
          'colors',
          'align',
          'fontFamily',
          'fontSize',
          'quickInsert',
          'paragraphFormat',
          'link',
          'lists',
          'table',
          'image',
          'draggable'
        ],
        toolbarButtons: [
          'bold',
          'italic',
          'color',
          'align',
          'fontFamily',
          'fontSize',
          'paragraphFormat',
          'insertLink',
          'formatOL',
          'formatUL',
          'recipient_name_placeholder',
          'recipient_company_placeholder',
          'insertTable',
          'insertImage'
        ],
        paragraphFormat: {
          N: 'Normal',
          H3: 'Heading'
        },
        fontFamily: HF_CUSTOM_STYLE_FONT_FAMILIES().reduce((all, current) => {
          all[current.rawValue] = current.label
          return all
        }, {}),
        paragraphFormatSelection: true,
        linkAlwaysBlank: true,
        linkAlwaysNoFollow: false,
        linkEditButtons: ['linkOpen', 'linkEdit'],
        linkInsertButtons: [],
        linkList: [],
        heightMin: 300
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'userEmailSettings'])
  },

  mounted () {
    this.form.merge(this.userEmailSettings)
  },

  methods: {
    ...mapActions(['fetchUserRequest']),
    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) return

      try {
        await this.form.put('settings/email')

        await this.fetchUserRequest()

        this.$notify.success(this.$t('pages.settings.personal.email_settings.settings_saved'))
      } catch (error) {
        this.$displayRequestError(error)
      }
    }
  }
}
</script>
